<template>
  <main class="kb-main" id="kb-educourse">
    <LxpMobileHeader v-if="isMobile" :title="`${params.crseNm}`">
      <template v-slot:left>
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="clickBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </template>
    </LxpMobileHeader>
    <lxp-main-header v-else :show-back="true"/>
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="educourse-view-container">
        <div class="view-summary">
          <div class="course-summary">
            <article class="course-card">
              <div class="course-image" :style="isMobile? {'margin-top':'10px'} : {}">
                <figure v-if="isInit" class="image-figure">
                  <img v-if="params.thumb" :src="getThumbUrl(params.thumb)" alt="">
                  <img v-else-if="params.mstThumb" :src="getThumbUrl(params.mstThumb)" alt="">
                  <CourseThumb v-else :num="params.crseMstSn"/>
                </figure>
              </div>
              <div class="course-content">
                <div v-if="!isMobile" class="content-header">
                  <p class="title-label">수강신청 정보</p>
                  <h4 class="title">{{ params.crseNm }}</h4>
                </div>
                <div class="content-meta">
                  <span class="text">{{ params.orgnzNm }}</span>
                  <span class="text">연수기간 : {{
                      timestampToDateFormat(params.bgngDt, 'yyyy.MM.dd.w')
                    }} - {{ timestampToDateFormat(params.endDt, 'yyyy.MM.dd.w') }}</span>
                  <span v-if="params.crseMlg > 0" class="text">마일리지 {{ params.crseMlg }}</span>
                </div>
                <div v-if="params.rtrcnPsbltYn === 'Y'" class="content-notice">
                  <span class="text">신청기간 : {{ timestampToDateFormat(params.aplyBgngDt, 'yyyy.MM.dd.w') }} - {{ timestampToDateFormat(params.aplyEndDt, 'yyyy.MM.dd.w hh:mm') }}<br></span>
                  <span class="text">({{ timestampToDateFormat(params.rtrcnEndDt, 'yyyy.MM.dd.w hh:mm') }} 까지 취소가능)</span>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="view-contents">
          <!-- content-section:과정안내 -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">과정안내</h4>
            </header>
            <div class="section-content">
              <template v-if="isMobile">
                <div class="content-item">
                  <div class="title">과정명</div>
                  <div class="desc">
                    <p>{{ params.crseNm }}</p>
                  </div>
                </div>
                <div class="content-item">
                  <div class="title">과정요약</div>
                  <div class="desc">
                    <p class="td-text" v-html="params.crseSumup ? params.crseSumup : '-'"></p>
                  </div>
                </div>
                <div class="content-item">
                  <div class="title">과정상세</div>
                  <div class="desc">
                    <p class="td-text" v-html="params.crseDtl ? params.crseDtl : '-'"  style="display:block; word-break: keep-all;"></p>
                  </div>
                </div>
                <div class="content-item">
                  <div class="title">난이도</div>
                  <div class="desc">
                    <p>
                      {{ params.crseLvl === '01' ? '상' : params.crseLvl === '02' ? '중' : '하' }}
                    </p>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="kb-table kb-table-striped">
                  <table>
                    <colgroup>
                      <col style="width:250px;">
                      <col style="width:auto">
                    </colgroup>
                    <tbody>
                    <tr>
                      <th><span class="th-title">과정명</span></th>
                      <td><p class="td-text">{{ params.crseNm }}</p></td>
                    </tr>
                    <tr>
                      <th><span class="th-title">과정요약</span></th>
                      <td><p class="td-text" v-html="params.crseSumup ? params.crseSumup : '-'"></p></td>
                    </tr>
                    <tr>
                      <th><span class="th-title">과정상세</span></th>
                      <td><p class="td-text" v-html="params.crseDtl ? params.crseDtl : '-'"></p></td>
                    </tr>
                    <tr v-if="params.crseLvl > 0">
                      <th><span class="th-title">난이도</span></th>
                      <td><p class="td-text">
                        {{ params.crseLvl === '01' ? '상' : params.crseLvl === '02' ? '중' : '하' }}
                      </p></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </div>
          </section>
          <!-- content-section:수강신청 -->
          <!-- content-section:수료기준안내 -->
          <section v-if="finishItems.length > 0" class="content-section">
            <header class="section-header">
              <h4 class="title">수료기준안내</h4>
            </header>
            <div class="section-content">
              <template v-if="isMobile">
                <template v-for="(finish, index) in finishItems" :key="index">
                  <template v-if="index === 0">
                    <div class="content-item" v-if="finish.fnshTot > 0">
                      <div class="title">
                        총점
                      </div>
                      <div class="desc">
                        <p>
                          {{ finish.fnshTot }} 점 {{ finish.fnshTot === 100 ? '' : '이상' }}
                        </p>
                      </div>
                    </div>
                  </template>
                  <div class="content-item">
                    <div class="title">
                      {{ finish.fnshCrtrCdDcd === '2045001' ? finish.fnshCateNm : finish.lrnObjTyNm }}
                      <template v-if="finish.lrnObjTyCd === '100005'">({{ finish.evlTyNm }})</template>
                    </div>
                    <div class="desc">
                      <p>
                        {{ finish.etcCn }} {{ finish.fnshCondValue }} {{ finish.fnshCondNm }}
                        {{ finish.rfltRate > 0 ? '(가중치 '.concat(finish.rfltRate,' %)') : '' }}
                      </p>
                    </div>
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="kb-table kb-table-striped">
                  <table>
                    <colgroup>
                      <col style="width:250px;">
                      <col style="width:auto">
                    </colgroup>
                    <tbody>
                    <template v-for="(finish, index) in finishItems" :key="index">
                      <template v-if="index === 0">
                        <tr v-if="finish.fnshTot > 0">
                          <th><span class="th-title">총점</span></th>
                          <td><p class="td-text">{{ finish.fnshTot }} 점 {{ finish.fnshTot === 100 ? '' : '이상' }}</p></td>
                        </tr>
                      </template>
                      <tr>
                        <th v-if="finish.fnshCrtrCdDcd === '2045001'"><span class="th-title">
                          {{ finish.fnshCateNm }}
                          <template v-if="finish.lrnObjTyCd === '100005'">({{ finish.evlTyNm }})</template>
                        </span></th>
                        <th v-else-if="finish.fnshCrtrCdDcd === '2045002'"><span
                            class="th-title">{{ finish.lrnObjTyNm }}</span></th>
                        <td>
                          <p class="td-text">{{ finish.etcCn }} {{ finish.fnshCondValue }} {{ finish.fnshCondNm }} {{ finish.rfltRate > 0 ? '(가중치 '.concat(finish.rfltRate,' %)') : '' }}</p>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </template>
            </div>
          </section>

          <!-- content-section:학습객체 -->
          <section v-if="chapItems.length > 0" class="content-section section-curriculum">
            <header class="section-header">
              <h4 class="title">학습목차</h4>
            </header>
            <div class="section-content">
              <template v-if="isMobile">
                <template v-for="(chap, index) in chapItems" :key="index">
                  <div class="content-item">
                    <div class="desc">
                      <p><span>Lesson {{ index + 1 }} {{ chap.chapNm }}</span></p>
                    </div>
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="kb-table kb-table-striped">
                  <table>
                    <colgroup>
                      <col style="width:250px;">
                      <col style="width:auto">
                    </colgroup>
                    <tbody>
                    <tr v-for="(chap, index) in chapItems" :key="index">
                      <td>
                        <p class="td-text">
                          <span>Lesson {{ index + 1 }}</span>
                        </p>
                      </td>
                      <td>
                        <p class="td-text">
                          <span class="text">{{ chap.chapNm }}</span>
                        </p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </div>
          </section>

          <!-- content-section:주요일정 -->
          <section v-if="objItems.length > 0" class="content-section section-curriculum">
            <header class="section-header">
              <h4 class="title">주요일정</h4>
            </header>
            <div class="section-content">
              <template v-if="isMobile">
                <template v-for="(elem, index) in objItems" :key="index">
                  <div class="content-item">
                    <div class="desc">
                      <p><span>
                        {{ timestampToDateFormat(elem.objBgngDt, 'yyyy.MM.dd') }} -
                        {{ timestampToDateFormat(elem.objEndDt, 'yyyy.MM.dd') }}
                        &nbsp;&nbsp;&nbsp;{{ elem.objNm }}
                      </span></p>
                    </div>
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="kb-table kb-table-striped">
                  <table>
                    <colgroup>
                      <col style="width:250px;">
                      <col style="width:auto">
                      <col style="width:270px;">
                    </colgroup>
                    <tbody>
                    <tr v-for="(objItem, index) in objItems" :key="index">
                      <td>
                        <p class="td-text">
                          <span>{{ objItem.lrnObjTyNm }}</span>
                        </p>
                      </td>
                      <td>
                        <p class="td-text">
                          <span class="text">{{ objItem.objNm }}</span>
                        </p>
                      </td>
                      <td>
                        <p class="td-text">
                          <span class="text">
                            {{ timestampToDateFormat(objItem.objBgngDt, 'yyyy.MM.dd') }} -
                            {{ timestampToDateFormat(objItem.objEndDt, 'yyyy.MM.dd') }}
                          </span>
                        </p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </div>
          </section>
        </div>

        <div v-if="isInit" :class="{'page-buttons': isMobile, 'view-bottom': !isMobile}">
          <template v-if="params.cntstCrseYn === 'Y' ">
            <template v-if="params.svyRsltCnt > 0">
              <button v-if="isMobile" class="kb-btn kb-btn-primary" @click="preOpenSurvey"><span class="text">수강신청</span></button>
              <button v-else class="kb-btn kb-btn-primary" @click="preOpenSurvey"><span class="text">수강신청</span></button>
            </template>
            <template v-else>
              <button v-if="isMobile" class="kb-btn kb-btn-primary" @click="applyAgree"><span class="text">수강신청</span></button>
              <button v-else class="kb-btn kb-btn-primary" @click="chkApplyModal"><span class="text">수강신청</span></button>
            </template>
          </template>
          <template v-else>
            <button v-if="isMobile" class="kb-btn kb-btn-primary" @click="applyAgree"><span class="text">수강신청</span></button>
            <button v-else class="kb-btn kb-btn-primary" @click="chkApplyModal"><span class="text">수강신청</span></button>
          </template>
        </div>
        <div style="margin-bottom: 30px"></div>
      </div>
    </div>
    <!-- //main-content -->
    <ApplyTrainModal
        v-if="applyModal"
        v-model="applyModal"
        :params="params"
        :lrner-objs="lrnerObjs"
        :learns="learns"
        :licenses="licenses"
        :objItems="objItems"
        :exItems="exItems"
        :shipInfo="shipInfo"
        @showSurvey="openSurvey"
    />

    <LearnSurvey
        v-if="surveyModal"
        v-model:active="surveyModal"
        :item="params"
        :svy="survey"
        :has-callback="true"
        @renewEnd="submitSurvey"
    />

  </main>

</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import ApplyTrainModal from '@/components/apply/ApplyTrainModal';
import {computed, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {getFunc, getListFunc} from '@/assets/js/ui.init';
import {
  ACT_GET_CHAP_DIST_LIST,
  ACT_GET_CRSE_DIST_APPLY,
  ACT_GET_CRSE_FNSH_DIST_LIST,
  ACT_GET_CRSE_DIST_COND
} from '@/store/modules/course/course';
import {getItem, getResult, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import CourseThumb from '@/components/apply/CourseThumb';
// import {getParentsLevel} from '@/assets/js/modules/common/common';
import LearnSurvey from '@/components/learn/window/LearnSurvey';
import {useAlert} from '@/assets/js/modules/common/alert';
import {useStore} from 'vuex';
import {ACT_GET_CNTST_SVY} from '@/store/modules/survey/survey';
import {applyCourse, getThumbUrl} from '@/assets/js/modules/course/course-common';
import navigationUtils from '@/assets/js/navigationUtils';
import LxpMobileHeader from '../../layout/lxp/header/mobile/LxpMobileHeader';
import {ACT_GET_CRSE_DIST_OBJ_APPLY, ACT_GET_CRSE_DIST_OBJ_LRNER} from '@/store/modules/course/course';
import {ACT_GET_LEARN_HISTORY_LIST} from '@/store/modules/learn/learn';
import {ACT_GET_LRN_SPRT_LCSN_LIST} from "@/store/modules/license/license";
import {ACT_GET_AUTH_PROFILE} from "@/store/modules/auth/auth";

export default {
  name: 'ApplyTrainView',
  components: {LxpMobileHeader, LearnSurvey, CourseThumb, ApplyTrainModal, LxpMainHeader},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showLoading, hideLoading, showMessage, showConfirm} = useAlert();
    const isMobile = navigationUtils.any();

    const isInit = ref(false);

    const applyModal = ref(false);



    const distCrseSn = computed(() => route.params.distCrseSn);
    const params = reactive({
      distCrseSn: 0,
      crseMstSn: 0,
      classCateSn: 0,
      classCateNm: '',
      mediaCateSn: 0,
      mediaCateNm: '',
      orgnzCdDcd: '',
      orgnzNm: '',
      prvdrSn: 0,
      prvdrNm: '',
      introDivCdDcd: '',
      introDivNm: '',

      crseNm: '',

      crseSumup: '',
      crseDtl: '',
      exptAblty: '',
      crseLvl: '',

      classUseYn: '',
      classCnt: 0,
      bgngDt: 0,
      endDt: 0,

      thumb: '',
      etcThumb: '',
      prevUrl: '',
      crseMlg: 0,

      totLrnPerid: 0,
      totLrnHr: 0,

      fnshCondYn: '', // 수료조건 여부
      rfltRateUseYn: '', // 반영 비율 사용 여부
      fnshCrtrUseYn: '', // 수료 기준 사용 여부
      fnshCrtrScore: 0, // 수료 기준 점수
      eduCost: 0, // 교육 비용
      unfiRmbrAmt: 0, // 미수료 환급 금액

      totBgt: 0, // 총 예산
      instrCost: 0, // 강사료

      lrnTrgtGrpMstSn: 0, // 학습 그룹

      eduTrnctCdDcd: '', // 연수원 DCD
      eduTrnctNm: '', // 연수원
      loc: '', // 장소

      // auditCfmtnCd : '',
      // auditCfmtnId : '',
      // auditCfmtnNm : '',
      // auditCfmtnDt : '',
      // fnshYn : '',
      // fnshPrcsRsnCd : '',
      // fnshPrcrId : '',
      // fnshPrcrNm : '',
      // fnshPrcsDt : '',

      // prsnAplyPsblt: '', // 개인 신청 가능 여부
      peoplCnt: 0, // 인원 수
      aplyBgngDt: 0, // 신청 시작 일시
      aplyEndDt: 0, // 신청 종료 일시

      rtrcnPsbltYn: '', // 신청 취소 강능 여부
      rtrcnEndDt: 0, // 취소 가능 종료일시

      cntstCrseYn: '', // 공모여부과정
      selecProcYn: '', // 공모여부과정
      mstThumb: '',
      stayAplyRcptYn: '',
      befCrseCd: '',
      svyRsltCnt: 0,
      dpcnRegCrseYn: '',
      rtakeCrsePsbltYy: '',
      bookYn: '',

      trprCfmtnYn:'',
      aplyTrgtDtlSn:0,
      splCrseTyCdDcd:'',

    });
    const chkApplyModal = () => {
      if (store.state.auth.session.lrnerId.substring(0, 2) === "99" ) {
        showMessage(`현지 채용 직원은 연수 미수료 환입, 교재 수령 이슈로 인해 연수신청이 불가합니다.<br><br> 관련 문의가 있으신 경우 HR부(인재개발) 최슬비대리(☎7880)에게 문의 바랍니다.`);
        return;
      }

      if(params.trprCfmtnYn ==='N'){ //신청대상자만 셋팅 확인
        if(params.aplyTrgtDtlSn === 0){
          showMessage('신청가능 대상자가 아닙니다.');
          return;
        }
      }

      if (applyModal.value === false && params.bookYn === 'Y' && shipInfo.value[0].shipAddr === null) {
        showConfirm(
            {
              title: '배송지 입력 안내',
              text: `해당 과정은 교재가 제공되는 과정이며, 배송지 주소를 등록하여야 수강신청이 가능합니다.<br>
                     배송지 등록 페이지로 이동하시겠습니까?`,
              callback: () => {
                router.push({name: 'MyProfile'});
              },
            },
        );
      } else {
        applyModal.value = true;
      }
    }
    const finishDists = ref([]);
    const finishItems = ref([]);
    const objItems = ref([]);
    const chapItems = ref([]);
    const currentDate = new Date();
    const currentTs = currentDate.getTime();
    const lrnerObjs = ref([]);
    const learns = ref([]);
    const licenses = ref([]);
    const exItems = ref([]);

    const shipInfo = ref([]);

    const getCallback = (isLen) => {
      params.crseSumup = params.crseSumup.replace(/(?:\r\n|\r|\n)/g, '<br />')
      params.crseDtl = params.crseDtl.replace(/(?:\r\n|\r|\n)/g, '<br />')


      // 데이터가 없을 경우
      if (!isLen) {
        console.log('잘못된 접근입니다.');
      } else {
        isInit.value = true;
        getListFunc(`course/${ACT_GET_CRSE_FNSH_DIST_LIST}`, distCrseSn.value, finishDists, null, () => {
          finishItems.value = finishDists.value.filter(x => x.fnshCondValue > 0);
        });
        getListFunc(`course/${ACT_GET_CHAP_DIST_LIST}`, distCrseSn.value, chapItems, null, () => {
          // chapItems.value = chapItems.value.map(x => {
          //   if (x.chapLvl > 1) {
          //     const parents = getParentsLevel(chapItems.value, x.upChapSn, 'distChapSn');
          //     return {...x, upChapOrd: parents.chapOrd};
          //   }
          //   return x;
          // });
        });
      }
    };

    const surveyModal = ref(false);
    const survey = ref({});

    const applyTargetCourse = () => {
      applyCourse(
          params.distCrseSn,
          {},
          () => {
            hideLoading();

            if(params.splCrseTyCdDcd === '2066030'){
              showMessage('수강신청이 완료되었습니다. ');
              router.push({name: 'TrainGlobal'});
            }else {
              showMessage('수강신청이 완료되었습니다.');
              router.push({name: 'ApplyStatus'});
            }
          },
          (res) => {
            hideLoading();
            showMessage(getResult(res).message);
          },
          () => {
            hideLoading();
            showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
          },
      );
    };

    const preOpenSurvey = () => {
      if (store.state.auth.session.lrnerId.substring(0, 2) === "99" ) {
        showMessage(`현지 채용 직원은 연수 미수료 환입, 교재 수령 이슈로 인해 연수신청이 불가합니다.<br><br> 관련 문의가 있으신 경우 HR부(인재개발) 최슬비대리(☎7880)에게 문의 바랍니다.`);
        return;
      }

      if (currentTs < params.aplyEndDt) {
        showConfirm(
            {
              title: '설문수정',
              text: `수강신청기간(${timestampToDateFormat(params.aplyBgngDt, 'yyyy.MM.dd')} ~
                    ${timestampToDateFormat(params.aplyEndDt,'yyyy.MM.dd')})동안<br>
                     제출하신 신청설문의 수정이 가능합니다.<br>
                     설문을 수정하시겠습니까?`,
              callback: () => {
                if (isMobile) {
                  router.push({name: 'ApplyCntst', params: {distCrseSn: params.distCrseSn}});
                } else {
                  openSurvey();
                }
              },
            },
        );
      } else {
        showMessage('설문수정 가능기간이 아닙니다.');
        return false;
      }
    }

    const openSurvey = () => {
      showLoading();
      store.dispatch(`survey/${ACT_GET_CNTST_SVY}`, params.distCrseSn).then(res => {
        // 설문 정보가 존재한다.
        if (lengthCheck(res)) {
          console.log("@@")
          survey.value = getItem(res);
          surveyModal.value = true;
        } else {
          applyTargetCourse();
          // 공모과정 설문이 존재하지 않는다...
        }
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 다시 시도해주세요.');
        hideLoading();
      });
    };

    const submitSurvey = () => {
      showLoading();
      applyTargetCourse();

    };

    getFunc(`course/${ACT_GET_CRSE_DIST_APPLY}`, distCrseSn.value, params, getCallback);
    getListFunc(`course/${ACT_GET_CRSE_DIST_OBJ_APPLY}`, distCrseSn.value, objItems, null);
    getListFunc(`course/${ACT_GET_CRSE_DIST_OBJ_LRNER}`, {}, lrnerObjs, null);
    getListFunc(`learn/${ACT_GET_LEARN_HISTORY_LIST}`, {}, learns, null, () => {
      learns.value = learns.value
                        .map(x => ({...x, fnshYn: [3092, 3093, 10955, 10956].includes(x.crseMstSn) ? 'Y' : x.fnshYn}))
                        .filter(y => y.auditCfmtnCd === 'Y').filter(x => x.fnshYn === 'Y');
    });
    getListFunc(`license/${ACT_GET_LRN_SPRT_LCSN_LIST}`, {regYn: 'Y'}, licenses, null,()=>{});
    setTimeout(() =>
      getListFunc(`course/${ACT_GET_CRSE_DIST_COND}`, {crseMstSn: params.crseMstSn}, exItems, null, () => {})
    , 600);

    getListFunc(`auth/${ACT_GET_AUTH_PROFILE}`, {}, shipInfo, null);

    const clickBack = () => {
      if (params.crseMstSn === 100331 || params.crseMstSn === 100332) {
        router.push({name: 'TrainDigital'});
      } else if (params.crseMstSn === 15185 || params.crseMstSn === 100033 || params.crseMstSn === 15870 || params.crseMstSn === 100034) {
          router.push({name: 'TrainStarLink'});
      } else if (params.splCrseTyCdDcd === '2066030') {
        router.push({name: 'TrainGlobal'});
      }else {
        router.push({name: 'ApplyTrain'});
      }
    };

    const applyAgree = () => {

      if (store.state.auth.session.lrnerId.substring(0, 2) === "99" ) {
        showMessage(`현지 채용 직원은 연수 미수료 환입, 교재 수령 이슈로 인해 연수신청이 불가합니다.<br><br> 관련 문의가 있으신 경우 HR부(인재개발) 최슬비대리(☎7880)에게 문의 바랍니다.`);
        return;
      }

      if (applyModal.value === false && params.bookYn === 'Y' && shipInfo.value[0].shipAddr === null) {
        showConfirm(
            {
              title: '배송지 입력 안내',
              text: `해당 과정은 교재가 제공되는 과정이며, 배송지 주소를 등록하여야 수강신청이 가능합니다.<br>
                     배송지 등록 페이지로 이동하시겠습니까?`,
              callback: () => {
                router.push({name: 'MyProfile'});
              },
            },
        );
      } else {
        router.push({name: 'ApplyAgree', params:{distCrseSn: distCrseSn.value}});
      }
    }

    return {
      isInit,
      applyModal,
      params,
      finishItems,
      objItems,
      lrnerObjs,
      chapItems,
      learns,
      licenses,
      exItems,
      shipInfo,

      surveyModal,
      survey,
      preOpenSurvey,
      openSurvey,
      submitSurvey,
      getThumbUrl,
      timestampToDateFormat,
      isMobile,
      clickBack,
      applyAgree,
      chkApplyModal,
    };
  },
};
</script>
